<template>
  <div
    class="relatedLetters pb-6"
    :style="type != 'internal' ? 'padding-left:0px' : ''"
  >
    <v-card class="letter-card">
      <v-card class="text-center pa-10 cards" v-if="!isLoaded">
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <circle-4></circle-4>
          <h6 class="mt-6">در حال بارگذاری ...</h6>
        </div>
      </v-card>
      <div v-else>
        <div class="letter-header grey--text text--darken-2">
          <v-row>
            <v-col>
              <div class="mb-5">
                <h5>
                  <v-icon class="pe-3">$EditUser</v-icon> فرستنده:
                  {{ letterInfo.sender }}
                </h5>
              </div>
              <div>
                <h5>
                  <v-icon class="pe-3">$TickUser</v-icon> گیرنده:
                  {{ letterInfo.receiver }}
                </h5>
              </div>
              <div v-if="letterInfo.assigne" class="mt-5">
                <h5>
                  <v-icon class="pe-3">mdi-arrow-left-circle-outline</v-icon>
                  تخصیص به:
                  {{ letterInfo.assigne }}
                </h5>
              </div>
              <div class="mb-6 mt-10">
                <span
                  >تاریخ ارسال:
                  <span dir="ltr">{{ letterInfo.date }}</span></span
                >
              </div>
              <div>
                <span>شماره نامه: {{ letterInfo.letterNo }}</span>
              </div>
            </v-col>
            <v-col class="d-flex flex-column justify-space-between">
              <div class="d-flex flex-row justify-content-end">
                <div>
                  <v-btn
                    :to="
                      '/' +
                      role +
                      '/secretariat/' +
                      type +
                      '/letterInfo/' +
                      $route.params.id
                    "
                    height="45"
                    class="ps-6 pe-6"
                    :class="
                      type == 'inbox'
                        ? 'purple-borderedBtn'
                        : 'blue-borderedBtn'
                    "
                    >بازگشت به نامه</v-btn
                  >
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <hr color="#eee" class="my-7" />
        <div class="grey--text text--darken-2">
          <v-row>
            <v-col>
              <h5>نامه‌های مرتبط</h5>
            </v-col>
            <v-col class="text-left">
              <v-btn
                @click="
                  lettersSearch();
                  addLetterDialog = true;
                  getEmployees();
                  getContacts();
                  getSignatories();
                "
                :class="type == 'inbox' ? 'purple-mainBtn' : 'blue-mainBtn'"
                height="45"
                >افزودن نامه مرتبط</v-btn
              >
            </v-col>
          </v-row>

          <div v-if="letterInfo.relatedLetters">
            <div
              v-if="!letterInfo.relatedLetters.length"
              class="mt-6 text-right"
            >
              <p class="mb-2">این نامه در حال حاضر با هیچ نامه‌ای مرتبط نیست</p>
              <p>
                برای افزودن نامه مرتبط از طریق دکمه‌ی افزودن نامه مرتبط اقدام
                کنید.
              </p>
            </div>
            <div v-else class="custom-table mt-6">
              <b-table
                responsive
                show-empty
                stacked="sm"
                :items="letterInfo.relatedLetters"
                :fields="fields2"
                empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
                empty-text="رکوردی برای نمایش وجود ندارد"
                striped
                thead-class="headClass"
                tbody-class="bodyClass"
              >
                <template v-slot:cell(index)="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-lg-3 pt-0"
                  >
                    {{ data.index + 1 }}
                  </div>
                </template>
                <template v-slot:head()="data">
                  <div style="text-align: center; vertical-align: middle">
                    {{ data.label }}
                  </div>
                </template>
                <template v-slot:cell()="data">
                  <div
                    style="text-align: center; vertical-align: middle"
                    class="pt-lg-3 pt-0"
                  >
                    {{ data.value }}
                  </div>
                </template>

                <template v-slot:cell(operation)="data">
                  <div style="text-align: center; vertical-align: middle">
                    <v-btn
                      @click="removeLetters(data.index)"
                      height="39"
                      width="90"
                      class="red-mainBtn pa-3"
                      :loading="loading"
                    >
                      حذف
                    </v-btn>
                  </div>
                </template>
                <div slot="table-busy" class="text-center text-danger my-2">
                  <strong>در حال بارگذاری...</strong>
                  <br />
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    class="align-middle"
                  ></v-progress-circular>
                </div>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="addLetterDialog" persistent width="800" class="mt-5">
      <v-card
        style="max-height: 630px"
        class="modal-card grey--text text--darken-2"
      >
        <v-row>
          <v-col>
            <h5 class="mt-3">افزودن نامه مرتبط</h5>
          </v-col>
          <v-col class="text-left">
            <v-btn
              @click="addLetterDialog = false"
              height="45"
              width="95"
              :class="
                type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
              "
              >بازگشت</v-btn
            >
          </v-col>
        </v-row>
        <v-row class="custom-input">
          <v-col class="pb-2 d-flex flex-row"
            ><v-select
              label="نوع نامه"
              outlined
              dense
              :items="letterTypes"
              v-model="filter.type"
              hide-details
              @change="
                filter.sender = '';
                filter.receiver = '';
              "
            >
            </v-select>
            <v-btn
              v-if="filter.type"
              @click="filter.type = ''"
              class="ms-2 red-borderedBtn"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Trash</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <div v-if="!filter.type" class="pt-3 pb-2 text-right">
          <span class="red--text"
            >برای تعیین نام فرستنده و نام گیرنده نوع نامه را مشخص کنید.</span
          >
        </div>
        <v-row class="custom-input">
          <v-col class="pb-2 d-flex flex-row"
            ><v-select
              label="نام فرستنده"
              outlined
              dense
              item-text="name"
              item-value="id"
              :items="filter.type == 'inbox' ? contacts : employees"
              v-model="filter.sender"
              :class="!filter.type ? 'disabled-box' : ''"
            ></v-select>
            <v-btn
              v-if="filter.sender"
              @click="filter.sender = ''"
              class="ms-2 red-borderedBtn"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Trash</v-icon></v-btn
            >
          </v-col>
          <v-col class="pb-2 d-flex flex-row"
            ><v-select
              label="نام گیرنده"
              outlined
              dense
              item-text="name"
              item-value="id"
              :items="filter.type == 'outbox' ? contacts : employees"
              v-model="filter.receiver"
              :class="!filter.type ? 'disabled-box' : ''"
            ></v-select>
            <v-btn
              v-if="filter.receiver"
              @click="filter.receiver = ''"
              class="ms-2 red-borderedBtn"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Trash</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-row class="custom-input">
          <v-col class="pb-2 d-flex flex-row"
            ><v-text-field
              label="جستجو در شماره نامه"
              outlined
              dense
              v-model="filter.letterNo"
            ></v-text-field>
            <v-btn
              v-if="filter.letterNo"
              @click="filter.letterNo = ''"
              class="ms-2 red-borderedBtn"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Trash</v-icon></v-btn
            >
          </v-col>

          <v-col class="pb-2 d-flex flex-row"
            ><v-text-field
              label="جستجو در موضوع نامه"
              outlined
              dense
              v-model="filter.title"
            ></v-text-field>
            <v-btn
              v-if="filter.title"
              @click="filter.title = ''"
              class="ms-2 red-borderedBtn"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Trash</v-icon></v-btn
            >
          </v-col>
        </v-row>
        <div class="py-5 font-weight-bold text-right">
          <span>تاریخ ارسال نامه:</span>
        </div>
        <v-row class="custom-input">
          <v-col class="pb-2 d-flex flex-row"
            ><v-text-field
              label="از تاریخ"
              outlined
              dense
              id="letterStartDate"
              v-model="filter.startDate"
            ></v-text-field
            ><v-btn
              class="ms-2"
              :class="
                type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
              "
              id="letterStartDate"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Calendar</v-icon></v-btn
            >
            <date-picker
              element="letterStartDate"
              v-model="filter.startDate"
              :color="
                type == 'inbox'
                  ? '#7E57C2'
                  : type == 'outbox'
                  ? '#00ACC1'
                  : '#4C81C9'
              "
            ></date-picker>
          </v-col>
          <v-col class="pb-2 d-flex flex-row"
            ><v-text-field
              label="تا تاریخ"
              outlined
              dense
              id="letterEndDate"
              v-model="filter.endDate"
            ></v-text-field
            ><v-btn
              class="ms-2"
              :class="
                type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
              "
              id="letterEndDate"
              width="40"
              min-width="40"
              height="40"
              ><v-icon>$Calendar</v-icon></v-btn
            >
            <date-picker
              element="letterEndDate"
              v-model="filter.endDate"
              :color="
                type == 'inbox'
                  ? '#7E57C2'
                  : type == 'outbox'
                  ? '#00ACC1'
                  : '#4C81C9'
              "
            ></date-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="borderBox">
              <div class="box-title">جستجو در تگ</div>
              <div class="custom-input mt-2 d-flex flex-row">
                <v-text-field
                  placeholder="عنوان تگ را وارد کنید..."
                  outlined
                  dense
                  v-model="tagName"
                  @keyup.enter="addTag"
                >
                </v-text-field>
                <v-btn
                  :class="
                    type == 'inbox' ? 'purple-borderedBtn' : 'blue-borderedBtn'
                  "
                  class="ms-3 pa-3"
                  height="39"
                  @click="addTag()"
                  >افزودن</v-btn
                >
              </div>
              <div class="d-flex flex-row">
                <div
                  v-for="(item, index) in filter.tags"
                  :key="index"
                  :class="type == 'inbox' ? 'purple-badge' : 'blue-badge'"
                  class="me-2 mt-3"
                >
                  <span>{{ item }}</span>
                  <v-icon small class="pointer" @click="removeTag(index)"
                    >mdi-close</v-icon
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="text-left mt-4">
          <v-btn
            height="48"
            width="110"
            :class="type == 'inbox' ? 'purple-mainBtn' : 'blue-mainBtn'"
            @click="lettersSearch"
          >
            <v-icon class="me-1">$Magnify</v-icon> جستجو</v-btn
          >
        </div>
        <div class="custom-table mt-8">
          <b-table
            responsive
            show-empty
            stacked="sm"
            :current-page="currentPage"
            :per-page="perPage"
            :items="relatedLetters"
            :fields="fields"
            empty-filtered-text="جستجو نتیجه‌ای در بر نداشت"
            empty-text="رکوردی برای نمایش وجود ندارد"
            striped
            thead-class="headClass"
            tbody-class="bodyClass"
            :busy="isBusy"
          >
            <template v-slot:cell(index)="data">
              <div
                style="text-align: center; vertical-align: middle"
                class="pt-lg-3 pt-0"
              >
                {{ data.index + 1 + perPage * (currentPage - 1) }}
              </div>
            </template>
            <template v-slot:head()="data">
              <div style="text-align: center; vertical-align: middle">
                {{ data.label }}
              </div>
            </template>
            <template v-slot:cell()="data">
              <div
                style="text-align: center; vertical-align: middle"
                class="pt-lg-3 pt-0"
              >
                {{ data.value }}
              </div>
            </template>

            <template v-slot:cell(operation)="data">
              <div style="text-align: center; vertical-align: middle">
                <v-btn
                  @click="addLetters(data.item)"
                  height="40"
                  :loading="data.item == selected && loading"
                  :class="type == 'inbox' ? 'purple-mainBtn' : 'blue-mainBtn'"
                  class="pa-3"
                  >افزودن</v-btn
                >
              </div>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
              <strong>در حال بارگذاری...</strong>
              <br />
              <v-progress-circular
                indeterminate
                color="primary"
                class="align-middle"
              ></v-progress-circular>
            </div>
          </b-table>
          <div
            v-if="!isBusy && relatedLetters.length"
            :class="
              type == 'inbox'
                ? 'purple-custom-pagination'
                : 'blue-custom-pagination'
            "
            class="d-flex flex-row justify-content-end"
          >
            <v-btn @click="goToFirst()" class="navigation-btn"
              ><v-icon>$ArrowDoubleRight</v-icon></v-btn
            >
            <v-pagination
              v-model="currentPage"
              :length="Math.ceil(relatedLetters.length / perPage)"
              :total-visible="5"
              prev-icon="$ArrowLeft"
              next-icon="$ArrowRight"
            ></v-pagination>
            <v-btn @click="goToLast()" class="navigation-btn"
              ><v-icon>$ArrowDoubleLeft</v-icon></v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Axios from "axios";
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { Circle4 } from "vue-loading-spinner";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    Circle4,
  },
  props: ["type", "letterInfo", "isLoaded"],
  data() {
    return {
      currentPage: 1,
      perPage: 4,
      selectedFile: {},
      showBig: false,
      addLetterDialog: false,
      isBusy: false,
      filter: {
        type: "",
        sender: "",
        receiver: "",
        letterNo: "",
        title: "",
        tags: [],
        startDate: moment(new Date())
          .subtract(7, "day")
          .format("jYYYY/jMM/jDD"),
        endDate: moment(new Date()).format("jYYYY/jMM/jDD"),
      },
      fields: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "sender", label: "فرستنده" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "operation", label: "عملیات" },
      ],
      fields2: [
        { key: "index", label: "#" },
        { key: "letterNo", label: "شماره نامه" },
        { key: "title", label: "موضوع نامه" },
        { key: "date", label: "تاریخ ارسال" },
        { key: "operation", label: "عملیات" },
      ],
      letterTypes: [
        { value: "internal", text: "سازمانی" },
        { value: "inbox", text: "ورودی" },
        { value: "outbox", text: "خروجی" },
      ],
      employees: [],
      customEmployees: [],
      agentBusy: false,
      tagName: "",
      relatedLetters: [],
      relatedLettersId: [],
      loading: false,
      selected: {},
      contacts: [],
      role: window.localStorage.role,
    };
  },
  methods: {
    goToFirst() {
      this.currentPage = 1;
    },
    goToLast() {
      this.currentPage = Math.ceil(this.relatedLetters.length / this.perPage);
    },
    addTag() {
      this.filter.tags.push(this.tagName);
      this.tagName = "";
    },
    removeTag(index) {
      this.filter.tags.splice(index, 1);
    },
    getEmployees() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/internals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.employees = res.data;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    getContacts() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/externals",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.contacts = res.data.users.map((x) => {
            return {
              id: x.id,
              name: x.choiceName,
            };
          });
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    getSignatories() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/contacts/signatories",
        {},
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.signatories = res.data;
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    lettersSearch() {
      this.isBusy = true;
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letters/search",
        {
          senderId: this.filter.sender,
          receiverId: this.filter.receiver,
          letterNo: this.filter.letterNo,
          title: this.filter.title,
          type: this.filter.type,
          tags: this.filter.tags.length ? this.filter.tags : "",
          startDate: this.filter.startDate,
          endDate: this.filter.endDate,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          this.isBusy = false;
          if (res.status == 200) {
            this.relatedLetters = res.data;
            this.currentPage = 1;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.isBusy = false;
        });
    },
    addLetters(item) {
      this.loading = true;
      if (!this.letterInfo.relatedLetterIds.includes(item.id)) {
        this.letterInfo.relatedLetterIds.push(item.id);
        this.selected = item;
      } else {
        this.$toast(".این نامه پیش از این افزوده شده است", "error");
        this.loading = false;
        return;
      }
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/manageRelatedLetters",
        {
          letterId: this.$route.params.id,
          relatedLetterIds: this.letterInfo.relatedLetterIds,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            setTimeout(() => {
              this.$emit("getInfo");
            }, 200);
            this.loading = false;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.loading = false;
        });
    },
    removeLetters(index) {
      if ((index + 1) % 10 == 0) {
        this.currentPageLetter--;
      }
      this.letterInfo.relatedLetters.splice(index, 1);
      this.letterInfo.relatedLetterIds = [];
      this.letterInfo.relatedLetters.forEach((x) => {
        this.letterInfo.relatedLetterIds.push(x.id);
      });
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/manageRelatedLetters",
        {
          letterId: this.$route.params.id,
          relatedLetterIds: this.letterInfo.relatedLetterIds,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
  },
  computed: {},
  mounted() {},
};
</script>
<style lang="scss">
* {
  font-family: iransans;
}
@font-face {
  font-family: iransans;
  src: url("../../assets/fonts/IRANSans(FaNum).ttf");
}
.relatedLetters {
  .letter-card {
    border-radius: 17px;
    padding: 20px 20px 20px 25px;
    margin-top: -10px;
    .letter-header {
      .updown-btn {
        height: 35px !important;
        width: 35px !important;
        min-width: 35px !important;
        padding: 0 !important;
        border: 1px solid #7e57c2;
        border-radius: 8px;
        background-color: #fff;
      }
      .blue-borderedBtn {
        path {
          stroke: #4c81c9;
        }
      }
    }
    .letter-text {
      line-height: 30px;
      font-size: 15px;
    }
    .file-box {
      width: 250px;
      .v-image {
        border-radius: 12px 12px 0 0;
        background-size: cover;
        height: 190px;
      }
      .file-info {
        background-color: #f6f1fe;
        border-radius: 0 0 12px 12px;
      }
    }
    .action {
      .v-btn {
        &:nth-child(2),
        &:nth-child(3) {
          path {
            stroke: #7e57c2;
          }
        }
      }
    }
  }
}
.v-dialog {
  width: fit-content;
  z-index: 100000;
}
.modal-card {
  padding: 20px;
  overflow: auto;
  border-radius: 12px !important;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
    opacity: 0.5;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    width: 10px;
    border-radius: 10px;
  }
  .image-box {
    height: 550px;
    pointer-events: none;
  }
  .borderBox {
    border: 1px solid #a9a9a9;
    border-radius: 12px;
    padding: 15px;
    position: relative;
    min-height: 130px;
    .box-title {
      position: absolute;
      top: -10px;
      right: 15px;
      color: #727171;
      font-size: 13px;
      background-color: #fff;
      padding: 0 10px;
    }
  }
}
</style>
