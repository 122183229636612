<template>
  <div>
    <RelatedLetters
      :letterInfo="letterInfo"
      :letters="letters"
      :relatedLetters="relatedLetters"
      :type="'outbox'"
      :isLoaded="isLoaded"
      @getInfo="getInfo"
    />
  </div>
</template>
<script>
import Axios from "axios";
import RelatedLetters from "../../../components/letters/RelatedLetters.vue";
export default {
  components: {
    RelatedLetters,
  },
  data() {
    return {
      letterInfo: {},
      letters: [],
      relatedLetters: [],
      isLoaded: false,
    };
  },
  methods: {
    getInfo() {
      Axios.post(
        this.baseUrl + "/clinic/secretariat/letter/getInfo",
        {
          letterId: this.$route.params.id,
        },
        {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        }
      )
        .then((res) => {
          if (res.status == 200) {
            this.letterInfo = res.data;
            this.isLoaded = true;
          } else {
            const title = (
              <div>
                <h5>خطا </h5>
                <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
              </div>
            );
            this.toast(title, "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    addLetter(item) {
      if (!this.letterInfo.relatedLetters.includes(item)) {
        this.letterInfo.relatedLetters.push(item);
      } else {
        this.$toast(".این نامه پیش از این افزوده شده است", "error");
      }
      // this.isBusy = true;
      // Axios.post(
      //   this.baseUrl + "",
      //   {
      //       letterId: item.id
      //    },
      //   {
      //     headers: {
      //       Authorization: "Bearer: " + localStorage.getItem("token"),
      //     },
      //   }
      // )
      //   .then((res) => {
      //     this.isBusy = false;
      //     if (res.status == 200) {
      //       this.toast(res.data, 'success')
      //   if (!this.relatedLetters.includes(item)) {
      //     this.relatedLetters.push(item);
      //   } else {
      //     this.$toast(".این نامه پیش از این افزوده شده است", "error");
      //   }
      //     } else {
      //       const title = (
      //         <div>
      //           <h5>خطا </h5>
      //           <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
      //         </div>
      //       );
      //       this.toast(title, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     this.toast("خطا: " + err.response.data, "error");
      //     this.isBusy = false;
      //   });
    },
    removeLetter(index) {
      this.letterInfo.relatedLetters.splice(index, 1);
      // this.isBusy = true;
      // Axios.post(
      //   this.baseUrl + "",
      //   {
      //       letterId: this.$route.params.id
      //    },
      //   {
      //     headers: {
      //       Authorization: "Bearer: " + localStorage.getItem("token"),
      //     },
      //   }
      // )
      //   .then((res) => {
      //     this.isBusy = false;
      //     if (res.status == 200) {
      //       this.toast(res.data, 'success');
      //      this.relatedLetters.splice(index, 1);
      //     } else {
      //       const title = (
      //         <div>
      //           <h5>خطا </h5>
      //           <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
      //         </div>
      //       );
      //       this.toast(title, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     this.toast("خطا: " + err.response.data, "error");
      //     this.isBusy = false;
      //   });
    },
    getRelatedLetters() {
      // this.isBusy = true;
      // Axios.post(
      //   this.baseUrl + "",
      //   {
      //       letterId: this.$route.params.id
      //    },
      //   {
      //     headers: {
      //       Authorization: "Bearer: " + localStorage.getItem("token"),
      //     },
      //   }
      // )
      //   .then((res) => {
      //     this.isBusy = false;
      //     if (res.status == 200) {
      //       this.relatedLetters = res.data
      //     } else {
      //       const title = (
      //         <div>
      //           <h5>خطا </h5>
      //           <span>مشکلی پیش آمده. مجددا امتحان کنید.</span>
      //         </div>
      //       );
      //       this.toast(title, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     this.toast("خطا: " + err.response.data, "error");
      //     this.isBusy = false;
      //   });
    },
  },
  mounted() {
    this.getInfo();
    this.getRelatedLetters();
  },
};
</script>
